import offset from '../offset/offset';

const scrollTo = id => {
  const el = id && document.querySelector(`#${id}`);
  const scrollOptions = { top: 0, left: 0, behavior: 'smooth' };
  if (el) {
    window.scroll({
      ...scrollOptions,
      ...offset(el, { fixTop: -50 })
    });
  } else {
    window.scroll(scrollOptions);
  }
};

export default scrollTo;
