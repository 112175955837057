import React from 'react';
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CardActions,
  IconButton
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import SectionTitle from '../../../../components/SectionTitle/SectionTitle';
import styles from './Proyectos.module.css';
import SectionText from '../../../../components/SectionText/SectionText';
import adminImg from '../../../../assets/images/admin1.jpg';
import appsImg from '../../../../assets/images/apps1.jpg';
import platformImg from '../../../../assets/images/platform1.jpg';

const items = [
  {
    title: 'Sistemas de Administración Comercial',
    img: adminImg,
    list: [
      {
        text: 'Múltiples Sucursales'
      },
      {
        text: 'Múltiples Usuarios'
      },
      {
        text: 'Control de Stock'
      },
      {
        text: 'Cuentas de Cliente y Proveedor'
      },
      {
        text: 'Gestión de Ventas, Compras y Gastos'
      },
      {
        text: 'Control de Caja'
      }
    ],
    listMore: false
  },
  {
    title: 'Apps para Dispositivos Móbiles',
    img: appsImg,
    list: [
      {
        text: 'Smartphones y Tablets'
      },
      {
        text: 'Presencia en Play Store'
      },
      {
        text: 'Presencia en App Store'
      },
      {
        text: 'Notificaciones Push'
      },
      {
        text: 'Integración con Mercadopago'
      },
      {
        text: 'Contenido administrable'
      },
      {
        text: 'Integración con redes sociales'
      }
    ]
  },
  {
    title: 'Plataformas para Administración de Contenido',
    img: platformImg,
    list: [
      {
        text: 'Múltiples Usuarios'
      },
      {
        text: 'Manejo de Roles'
      },
      {
        text: 'Administración de productos'
      },
      {
        text: 'Galerías de imágenes'
      },
      {
        text: 'Noticias y comunicados'
      },
      {
        text: 'Publicidades y Eventos'
      }
    ],
    textMore: ''
  }
];

const Proyectos = () => {
  const cards = items.map(item => (
    <Grid item sm={6} xs={12} md={4} key={item.title}>
      <div className={styles.ProyectosCardWrapper}>
        <Card className={styles.ProyectosCard}>
          <CardMedia
            component="img"
            alt={item.text}
            image={item.img}
            title={item.title}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {item.title}
            </Typography>
            <List component="ul">
              {item.list.map((listItem, i) => (
                <ListItem key={i}>
                  <ListItemIcon>
                    <DoneIcon />
                  </ListItemIcon>
                  <ListItemText primary={listItem.text} />
                </ListItem>
              ))}
            </List>
          </CardContent>
          {item.listMore || item.textMore ? (
            <CardActions className={styles.ProyectosCardActions}>
              <IconButton aria-label="More">
                <AddIcon />
              </IconButton>
            </CardActions>
          ) : null}
        </Card>
      </div>
    </Grid>
  ));

  return (
    <section className={styles.Proyectos} id="proyectos">
      <SectionTitle title="Proyectos" />
      <SectionText
        text="Nuestras soluciones, totalmente personalizadas,
          están pensadas para cumplir con los requerimientos más exigentes de
          nuestros clientes."
      />
      <div className={styles.ProyectosGrid}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          {cards}
        </Grid>
      </div>
    </section>
  );
};

export default Proyectos;
