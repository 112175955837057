import axios from 'axios';

class API {
  constructor({ url }) {
    this.url = url;
    this.endpoints = {};
  }

  createEntity = entity => {
    this.endpoints[entity.name] = this.createBasicCRUDEndpoints(entity);
  };

  createEntities = arrayOfEntities => {
    arrayOfEntities.forEach(this.createEntity);
  };

  createBasicCRUDEndpoints = ({ name }) => {
    const endpoints = {};
    const resourceURL = `${this.url}/${name}`;
    endpoints.getAll = ({ query } = {}) =>
      axios.get(resourceURL, { params: query || {} });
    endpoints.getOne = ({ id }) => axios.get(`${resourceURL}/${id}`);
    endpoints.create = ({ toCreate }) => axios.post(resourceURL, toCreate);
    endpoints.update = ({ toUpdate }) =>
      axios.put(`${resourceURL}/${toUpdate.id}`, toUpdate);
    endpoints.delete = ({ id }) => axios.delete(`${resourceURL}/${id}`);

    return endpoints;
  };
}

export default API;
