import React, { Fragment } from 'react';
import MainBar from '../../components/MainBar/MainBar';
import Footer from '../../components/Footer/Footer';

const Layout = props => {
  return (
    <Fragment>
      <MainBar />
      <main>{props.children}</main>
      <Footer />
    </Fragment>
  );
};

export default Layout;
