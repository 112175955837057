import React from 'react';
import styles from './MainSec.module.css';
import mainImg from '../../../../assets/images/main-bg.jpg';

const Main = () => {
  return (
    <div className={styles.MainSec} id="main">
      <div
        className={styles.MainSecImgBg}
        style={{ background: `url(${mainImg})` }}
      >
        <div className={styles.MainSecTextWrapper}>
          <div className={styles.MainSecTextHeader}>
            <h1>Woper</h1>
            <h5>Trabajo, Responsabilidad y Compromiso</h5>
          </div>
        </div>
        <div className={styles.MainSecTextContent}>
          <p>
            Nos dedicamos a desarrollar y brindar las mejores soluciones
            informáticas de acuerdo a las necesidades de nuestros clientes.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Main;
