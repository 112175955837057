import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';

const materialStyles = theme => ({
  footer: {
    backgroundColor: theme.palette.grey[900],
    padding: `${theme.spacing.unit * 3}px 0`,
    color: '#fff'
  },
  icon: {
    color: 'red',
    verticalAlign: 'top'
  }
});

const Footer = ({ classes }) => {
  return (
    <footer className={classes.footer}>
      <Typography
        variant="subtitle1"
        align="center"
        color="inherit"
        component="p"
      >
        Coded with <FavoriteIcon className={classes.icon} /> by Woper{' '}
        {new Date().getFullYear()} - Villa María, Córdoba Argentina
      </Typography>
    </footer>
  );
};

export default withStyles(materialStyles)(Footer);
