import React from 'react';
import { Snackbar } from '@material-ui/core';
import MySnackbarContent from './MySnackbarContent/MySnackbarContent';

const CustomSnackbar = ({ open, onClose, variant, message }) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <MySnackbarContent
        onClose={onClose}
        variant={variant}
        message={message}
      />
    </Snackbar>
  );
};

export default CustomSnackbar;
