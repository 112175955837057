const offset = (el, { fixTop, fixLeft } = { fixTop: 0, fixLeft: 0 }) => {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return {
    top: rect.top + scrollTop + fixTop,
    left: rect.left + scrollLeft + fixLeft
  };
};

export default offset;
