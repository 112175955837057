import React from 'react';
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography
} from '@material-ui/core';
import SectionTitle from '../../../../components/SectionTitle/SectionTitle';
import styles from './Servicios.module.css';
import devImg from '../../../../assets/images/dev2.jpg';
import manImg from '../../../../assets/images/man2.jpg';
import atImg from '../../../../assets/images/at2b.jpg';
import SectionText from '../../../../components/SectionText/SectionText';

const Servicios = () => {
  return (
    <section className={styles.Servicios} id="servicios">
      <SectionTitle title="Servicios" />
      <SectionText
        text="En Woper creemos que aunque muchos problemas se parezcan, las
          soluciones a los mismos pueden ser totalemtente diferentes, pero no
          por eso inválidas."
      />
      <div className={styles.ServiciosGrid}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          <Grid item sm={6} xs={12} md={4}>
            <div className={styles.ServiciosCardWrapper}>
              <Card className={styles.ServiciosCard}>
                <CardMedia
                  component="img"
                  alt="Desarrollo de Software"
                  image={devImg}
                  title="Contemplative Reptile"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Desarrollo
                  </Typography>
                  <Typography variant="subtitle1" component="p">
                    Sistemas Web, Páginas Web, Aplicaciones para dispositivos
                    iPhone y Android (smartphones - tablets) y Sistemas que
                    combinen estas Tecnologías.
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </Grid>
          <Grid item sm={6} xs={12} md={4}>
            <div className={styles.ServiciosCardWrapper}>
              <Card className={styles.ServiciosCard}>
                <CardMedia
                  component="img"
                  alt="Mantenimiento y Adminsitración de Software y Servicios"
                  image={manImg}
                  title="Contemplative Reptile"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Mantenimiento y Administración
                  </Typography>
                  <Typography variant="subtitle1" component="p">
                    De los servicios y tecnologías asociadas al desarrollo de
                    nuestros sistemas, hosting, emails, nombres de dominio,
                    bases de datos, actualizaciones, backups.
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </Grid>
          <Grid item sm={6} xs={12} md={4}>
            <div className={styles.ServiciosCardWrapper}>
              <Card className={styles.ServiciosCard}>
                <CardMedia
                  component="img"
                  alt="Soporte y Capacitación"
                  image={atImg}
                  title="Contemplative Reptile"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Soporte y Capacitación
                  </Typography>
                  <Typography variant="subtitle1" component="p">
                    Siempre brindando lo mejor de nosotros para la satisfacción
                    de nuestros clientes!
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

export default Servicios;
