import React, { Component } from 'react';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Grid,
  withStyles,
  Button,
  CircularProgress
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import api from '../../api/api';
import CustomSnackbar from '../CustomSnackbar/CustomSnackbar';
import styles from './ContactoForm.module.css';

const materialStyles = theme => ({
  textField: {
    marginRight: theme.spacing.unit,
    width: '100%'
  },
  submitButton: {
    margiinTop: theme.spacing.unit,
    width: '100%'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  submitButtonWrapper: {
    marginTop: '15px',
    position: 'relative'
  }
});

class ContactoForm extends Component {
  state = {
    snackbar: {
      open: false,
      message: '',
      variant: ''
    },
    loading: false,
    formData: {
      nombre: {
        value: '',
        label: 'Nombre',
        config: {
          name: 'nombre',
          id: 'nombre',
          type: 'text',
          required: true
        },
        validate: {
          maxLength: 200,
          minLength: 2
        }
      },
      apellido: {
        value: '',
        label: 'Apellido',
        config: {
          name: 'apellido',
          id: 'apellido',
          type: 'text',
          required: true
        },
        validate: {
          maxLength: 200,
          minLength: 2
        }
      },
      email: {
        value: '',
        label: 'Email',
        config: {
          name: 'email',
          id: 'email',
          type: 'email',
          required: true
        },
        validate: {
          maxLength: 200
        }
      },
      telefono: {
        value: '',
        label: 'Teléfono',
        config: {
          name: 'telefono',
          id: 'telefono',
          type: 'tel'
        },
        validate: {
          max: 50,
          min: 5
        }
      },
      localidad: {
        value: '',
        label: 'Localidad',
        config: {
          name: 'localidad',
          id: 'localidad',
          type: 'text'
        },
        validate: {
          maxLength: 200,
          minLength: 2
        }
      },
      provincia: {
        value: '',
        label: 'Provincia',
        config: {
          name: 'provincia',
          id: 'provincia',
          type: 'text'
        },
        validate: {
          maxLength: 200,
          minLength: 2
        }
      },
      mensaje: {
        value: '',
        label: 'Mensaje',
        config: {
          name: 'mensaje',
          id: 'mensaje',
          required: true
        },
        validate: {
          maxLength: 500,
          minLength: 10
        }
      }
    }
  };

  updateForm = name => event => {
    const formData = { ...this.state.formData };
    formData[name].value = event.target.value;
    this.setState({ formData });
  };

  toggleLoading = () => {
    this.setState(prevState => ({ loading: !prevState.loading }));
  };

  submitForm = e => {
    e.preventDefault();
    this.toggleLoading();
    api.endpoints.mensajesPrivados
      .create({ toCreate: this.getDataToSubmit(this.state.formData) })
      .then(
        res => {
          this.resetForm();
          this.toggleLoading();
          this.showSnackbar('success', '¡Gracias por Contactarte!');
        },
        err => {
          console.log(err);
          this.toggleLoading();
          this.showSnackbar('error', 'Ups, inténtalo de nuevo más tarde!');
        }
      );
  };

  resetForm = () => {
    const formData = { ...this.state.formData };
    for (const key in formData) {
      formData[key].value = '';
    }
    this.setState({ formData });
  };

  getDataToSubmit = form => {
    const dataToSubmit = {};
    for (const key in form) {
      dataToSubmit[key] = form[key].value;
    }
    return dataToSubmit;
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    const snackbar = { ...this.state.snackbar };
    snackbar.open = false;
    this.setState({ snackbar });
  };

  showSnackbar = (variant, message) => {
    const snackbar = { ...this.state.snackbar };
    snackbar.open = true;
    snackbar.variant = variant;
    snackbar.message = message;
    this.setState({ snackbar });
  };

  render() {
    const { classes } = this.props;
    const { formData } = this.state;
    return (
      <div className={styles.ContactoFormCardWrapper}>
        <CustomSnackbar
          open={this.state.snackbar.open}
          onClose={this.handleCloseSnackbar}
          variant={this.state.snackbar.variant}
          message={this.state.snackbar.message}
        />
        <Card className={styles.ContactoFormCard}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Comunícate con nosotros!
            </Typography>
            <form onSubmit={this.submitForm}>
              <Grid
                className={styles.ContactoFormGrid}
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
                spacing={8}
              >
                <Grid item md={6} xs={12}>
                  <TextField
                    onChange={this.updateForm(formData.nombre.config.id)}
                    value={formData.nombre.value}
                    label={formData.nombre.label}
                    {...formData.nombre.config}
                    margin="normal"
                    variant="outlined"
                    className={classes.textField}
                    inputProps={formData.nombre.validate}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    onChange={this.updateForm(formData.apellido.config.id)}
                    value={formData.apellido.value}
                    label={formData.apellido.label}
                    {...formData.apellido.config}
                    margin="normal"
                    variant="outlined"
                    className={classes.textField}
                    inputProps={formData.apellido.validate}
                  />
                </Grid>
              </Grid>
              <Grid
                className={styles.ContactoFormGrid}
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
                spacing={8}
              >
                <Grid item md={6} xs={12}>
                  <TextField
                    onChange={this.updateForm(formData.email.config.id)}
                    value={formData.email.value}
                    label={formData.email.label}
                    {...formData.email.config}
                    className={classes.textField}
                    autoComplete="email"
                    margin="normal"
                    variant="outlined"
                    inputProps={formData.email.validate}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    onChange={this.updateForm(formData.telefono.config.id)}
                    value={formData.telefono.value}
                    label={formData.telefono.label}
                    {...formData.telefono.config}
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid
                className={styles.ContactoFormGrid}
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
                spacing={8}
              >
                <Grid item md={6} xs={12}>
                  <TextField
                    onChange={this.updateForm(formData.localidad.config.id)}
                    value={formData.localidad.value}
                    label={formData.localidad.label}
                    {...formData.localidad.config}
                    margin="normal"
                    variant="outlined"
                    className={classes.textField}
                    inputProps={formData.localidad.validate}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    onChange={this.updateForm(formData.provincia.config.id)}
                    value={formData.provincia.value}
                    label={formData.provincia.label}
                    {...formData.provincia.config}
                    margin="normal"
                    variant="outlined"
                    className={classes.textField}
                    inputProps={formData.provincia.validate}
                  />
                </Grid>
              </Grid>
              <Grid
                className={styles.ContactoFormGrid}
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
                spacing={8}
              >
                <Grid item md={12} xs={12}>
                  <TextField
                    onChange={this.updateForm(formData.mensaje.config.id)}
                    value={formData.mensaje.value}
                    label={formData.mensaje.label}
                    {...formData.mensaje.config}
                    multiline
                    rows="4"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    inputProps={formData.mensaje.validate}
                  />
                </Grid>
              </Grid>
              <div className={classes.submitButtonWrapper}>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  type="submit"
                  disabled={this.state.loading}
                  className={classes.submitButton}
                >
                  Enviar
                </Button>
                {this.state.loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default withStyles(materialStyles)(ContactoForm);
