import React from 'react';
import styles from './SectionText.module.css';

const SectionText = ({ text }) => {
  return (
    <div className={styles.SectionText}>
      <p>{text}</p>
    </div>
  );
};

export default SectionText;
