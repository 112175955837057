import React, { Component } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Hidden,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  withStyles,
  Divider
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import scrollTo from '../../helpers/scrollTo/scrollTo';
import styles from './MainBar.module.css';

const menuLinks = [
  {
    displayName: 'Home',
    scrollTo: ''
  },
  {
    displayName: 'Servicios',
    scrollTo: 'servicios'
  },
  // {
  //   displayName: 'Productos',
  //   scrollTo: ''
  // },
  {
    displayName: 'Proyectos',
    scrollTo: 'proyectos'
  },
  {
    displayName: 'Contacto',
    scrollTo: 'contacto'
  }
];

const materialStyles = theme => ({
  list: {
    width: 250
  },
  listWrapper: {
    display: 'flex',
    flexGrow: 1
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    ...theme.mixins.toolbar
  }
});

class MainBar extends Component {
  state = {
    menuOpen: false
  };

  toggleDrawer = () => {
    this.setState(prevState => {
      return {
        menuOpen: !prevState.menuOpen
      };
    });
  };

  renderButtons = () => {
    return menuLinks.map(item => (
      <Button
        key={item.displayName}
        color="inherit"
        onClick={() => scrollTo(item.scrollTo)}
      >
        {item.displayName}
      </Button>
    ));
  };

  renderList = () => {
    return (
      <div className={this.props.classes.list}>
        <div className={this.props.classes.toolbar} />
        <Divider />
        <List>
          {menuLinks.map(item => (
            <ListItem button key={item.displayName}>
              <ListItemText
                primary={item.displayName}
                color="inherit"
                onClick={() => scrollTo(item.scrollTo)}
              />
            </ListItem>
          ))}
        </List>
      </div>
    );
  };

  render() {
    return (
      <div className={styles.MainBar}>
        <AppBar position="fixed" color="primary">
          <Toolbar>
            <Hidden smUp>
              <IconButton
                color="inherit"
                aria-label="Menu"
                onClick={this.toggleDrawer}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
            <Typography
              color="inherit"
              variant="h6"
              className={styles.MainBarName}
            >
              Woper
            </Typography>
            <Hidden xsDown>{this.renderButtons()}</Hidden>
          </Toolbar>
        </AppBar>
        <Drawer
          component="nav"
          open={this.state.menuOpen}
          onClose={this.toggleDrawer}
        >
          <div
            className={this.props.classes.listWrapper}
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer}
            onKeyDown={this.toggleDrawer}
          >
            {this.renderList()}
          </div>
        </Drawer>
      </div>
    );
  }
}

export default withStyles(materialStyles)(MainBar);
