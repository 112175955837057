import React from 'react';
import MainSec from './sections/MainSec/MainSec';
import Servicios from './sections/Servicios/Servicios';
import Proyectos from './sections/Proyectos/Proyectos';
import Contacto from './sections/Contacto/Contacto';

const Home = () => {
  return (
    <div>
      <MainSec />
      <Servicios />
      <Proyectos />
      <Contacto />
    </div>
  );
};

export default Home;
