import React, { Fragment } from 'react';
import { BrowserRouter } from 'react-router-dom';
import CssBalseline from '@material-ui/core/CssBaseline';
import Layout from './hoc/Layout/Layout';
import Routes from './Routes';

const App = () => (
  <Fragment>
    <CssBalseline />
    <BrowserRouter>
      <Layout>
        <Routes />
      </Layout>
    </BrowserRouter>
  </Fragment>
);
export default App;
