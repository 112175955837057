import API from '../helpers/api/Api';
import { URL } from '../const';

const api = new API({ url: URL });
const BASIC_ENTITIES = [
  {
    name: 'mensajesPrivados'
  }
];
api.createEntities(BASIC_ENTITIES);

export default api;
