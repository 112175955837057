import React from 'react';
import { Grid } from '@material-ui/core';
import SectionTitle from '../../../../components/SectionTitle/SectionTitle';
import SectionText from '../../../../components/SectionText/SectionText';
import styles from './Contacto.module.css';
import ContactoForm from '../../../../components/ContactoForm/ContactoForm';

const Contacto = () => {
  return (
    <section className={styles.Contacto} id="contacto">
      <SectionTitle title="Contacto" />
      <SectionText text="¿Estas listo para que desarrollemos tu solución?" />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        className={styles.ContactoGrid}
      >
        <Grid item xs={12} sm={6} md={6}>
          <ContactoForm />
        </Grid>
      </Grid>
    </section>
  );
};

export default Contacto;
