import React from 'react';
import styles from './SectionTitle.module.css';

const SectionTitle = ({ title }) => {
  return (
    <div className={styles.SectionTitle}>
      <h1>{title}</h1>
      <hr />
    </div>
  );
};

export default SectionTitle;
